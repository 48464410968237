<div class="header">
  <div class="drop-down">
    <select (change)="onSelectItem($event)">
      <option style="display: none">
        {{
          !this.selectedPlo ? "All" : (this.selectedPlo.name | slice : 0 : 4)
        }}
      </option>
      <option value="-1">
        {{ "All" }}
      </option>
      @for (plo of this.plos(); track $index ) {
      <option [value]="plo._id">
        {{ plo.name | slice : 0 : 4 }}
      </option>
      }
    </select>
  </div>
  <button (click)="exportAsCSV()" type="button">{{ "common.export" | translate }}</button>
  <!--course-name-section-plo-achievement-->
</div>
<table>
  <tr class="heads">
    <th style="width: 100px">#</th>
    <th><div>CLOs</div></th>
    <th><div>Related PLO</div></th>
    <th><div>CLO Benchmark</div></th>
    <th>
      <div>Clo Achievement</div>
    </th>
    <th>
      <div></div>
    </th>
  </tr>
  @for (clo of (!!this.displayedClos ? this.displayedClos : this.clos()); track
  clo; let i = $index,last = $last) {

  <tr
    class="row"
    [style.border-bottom]="!last ? '1px solid #E8EBED9C' : 'none'"
  >
    <td style="width: 100px">{{ i + 1 }}</td>

    <td>
      {{ clo.name }}
    </td>
    <td>
      {{ clo.plo.name }}
    </td>

    <td class="benchmark">
      <p>{{ cloBenchmark() }}%</p>
    </td>

    @if (clo.achievement === -1) {
    <td class="unmeasured">
      <p>Unmeasured</p>
    </td>
    } @else {
    <td>
      <app-progress-bar
        [config]="{ percent: clo.achievement, benchmark: cloBenchmark() }"
      ></app-progress-bar>
    </td>
    }@if (clo.achievement === -1) { } @else {
    <td class="arrow" (click)="this.navigateToCloAchievement(clo)">
      <figure><img src="assets/svgs/icons/right-arrow.svg" alt="" /></figure>
    </td>
    }
  </tr>
  }
</table>
